import React from 'react';
import { Box, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from '../../firebase';
import Navigation from './Navigation';
import ClassView from './ClassView';

const ClassHeader = ({ classId }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('userType');
      navigate('/school-login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <AppBar position="static" color="primary" sx={{ bgcolor: getClassColor(classId) , zIndex: 1300 }}>
      <Toolbar>
        <Typography variant="h6">
          {getClassName(classId)}
        </Typography>
        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
        
          <Button
            color="inherit"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            sx={{ ml: 2 }}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const getClassName = (classId) => {
  const names = {
    'play-group': 'Play Group',
    'nursery-1': 'Nursery 1',
    'nursery-2': 'Nursery 2',
    'kindergarten-1': 'Kindergarten 1',
    'kindergarten-2': 'Kindergarten 2'
  };
  return names[classId] || '';
};

const getClassColor = (classId) => {
  const colors = {
    'play-group': '#8bc34a',
    'nursery-1': '#795548',
    'nursery-2': '#ff9800',
    'kindergarten-1': '#2196f3',
    'kindergarten-2': '#9c27b0'
  };
  return colors[classId] || '#1976d2';
};

const ClassContainer = () => {
  const { classId } = useParams();
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <ClassHeader classId={classId} />
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Navigation />
        <Box sx={{ flex: 1, overflow: 'auto', p: 3 }}>
          <ClassView />
        </Box>
      </Box>
    </Box>
  );
};

export default ClassContainer;