import React, { useEffect, useState } from 'react';
import { database } from '../../firebase';
import { ref, onValue } from 'firebase/database';
import { TableCell, TableRow, Box, Chip } from '@mui/material';

const StudentStatusRow = ({ student, onStatusChange }) => {
  const [schoolStatus, setSchoolStatus] = useState(null);
  const [profile, setProfile] = useState(null);
  const [busStatus, setBusStatus] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [lastBusUpdate, setLastBusUpdate] = useState(null);

  useEffect(() => {
    const schoolRef = ref(database, `status/${student.id}/school`);
    const busRef = ref(database, `status/${student.id}/bus`);

    const unsubscribeSchool = onValue(schoolRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setSchoolStatus(data.status);
        setProfile(data.profile);
        setLastUpdate(data.timestamp);
        onStatusChange(student.id, data.status);
      }
    });

    const unsubscribeBus = onValue(busRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setBusStatus(data.status);
        setLastBusUpdate(data.timestamp);
      }
    });

    return () => {
      unsubscribeSchool();
      unsubscribeBus();
    };
  }, [student.id]);

  const getChipColor = (status) => {
    switch (status) {
      case 'in': return 'success';
      case 'out': return 'warning';
      case 'pick': return 'info';
      case 'drop': return 'primary';
      case 'absent': return 'error';
      default: return 'default';
    }
  };

  return (
    <TableRow>
      {schoolStatus === 'in' &&
        <>
          <TableCell>{profile.nricFin}</TableCell>
          <TableCell>{profile?.name}</TableCell>
          <TableCell>
            {lastUpdate ? new Date(lastUpdate).toLocaleTimeString() : '-'}
          </TableCell>
          {/* <TableCell>
            {lastBusUpdate ? new Date(lastBusUpdate).toLocaleTimeString() : '-'}
          </TableCell> */}
          <TableCell>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Chip
                label={`School: ${schoolStatus || 'N.A'}`}
                color={getChipColor(schoolStatus)}
                size="small"
              />
              <Chip
                label={`Bus: ${busStatus || 'N.A'}`}
                color={getChipColor(busStatus)}
                size="small"
              />
            </Box>
          </TableCell>
        </>
      }
    </TableRow>
  );
};

export default StudentStatusRow;