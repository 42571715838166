import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import {
  Box,
  Button,
  Container,
  Typography,
  Paper
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const Welcome = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('userType');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography 
            component="h1" 
            variant="h4" 
            sx={{ 
              mb: 4,
              fontWeight: 'bold'
            }}
          >
            Welcome!
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            sx={{ 
              minWidth: 200,
              height: 48,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1.1rem'
            }}
          >
            Logout
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default Welcome;