import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { database } from '../../firebase';
import { ref, get, set, onValue } from 'firebase/database';
import {
    CircularProgress,
    Typography,
    Box,
    Button,
    Paper,
    RadioGroup,
    Radio,
    FormControlLabel,
    Grid,
    IconButton
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

function Profile() {
    const { deviceId } = useParams();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [busStatus, setBusStatus] = useState('');
    const [schoolStatus, setSchoolStatus] = useState('');
    const [currentBusStatus, setCurrentBusStatus] = useState(null);
    const [currentSchoolStatus, setCurrentSchoolStatus] = useState(null);
    const [inTime, setInTime] = useState(null);
    const [outTime, setOutTime] = useState(null);
    const [userType] = useState(() => localStorage.getItem('userType'));

    useEffect(() => {
        let intervalId;

        const updateLocation = async () => {
            if (userType === 'bus' && "geolocation" in navigator) {
                try {
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject);
                    });

                    const { latitude, longitude } = position.coords;
                    const locationRef = ref(database, `busLocations/${deviceId}`);
                    await set(locationRef, {
                        latitude,
                        longitude,
                        timestamp: Date.now(),
                        status: currentBusStatus
                    });
                } catch (error) {
                    console.error('Error updating location:', error);
                }
            }
        };

        if (userType === 'bus') {
            updateLocation();
            intervalId = setInterval(updateLocation, 60000); // Update every minute
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [userType, deviceId, currentBusStatus]);

    useEffect(() => {
        const busStatusRef = ref(database, `status/${deviceId}/bus`);
        const schoolStatusRef = ref(database, `status/${deviceId}/school`);

        const unsubscribeBusStatus = onValue(busStatusRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setCurrentBusStatus(data.status);
                setBusStatus(data.status);
            }
        });

        const unsubscribeSchoolStatus = onValue(schoolStatusRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setCurrentSchoolStatus(data.status);
                setSchoolStatus(data.status);
                
                // Update times from database
                if (data.inTime) {
                    setInTime(data.inTime);
                }
                if (data.outTime) {
                    setOutTime(data.outTime);
                }
            }
        });

        const fetchProfile = async () => {
            try {
                const deviceRef = ref(database, `devices/${deviceId}`);
                const deviceSnapshot = await get(deviceRef);

                if (deviceSnapshot.exists()) {
                    const deviceData = deviceSnapshot.val();
                    const defaultProfileId = deviceData.defaultProfileId;

                    if (defaultProfileId) {
                        const profileRef = ref(database, `devices/${deviceId}/profiles/${defaultProfileId}`);
                        const profileSnapshot = await get(profileRef);

                        if (profileSnapshot.exists()) {
                            setProfile(profileSnapshot.val());
                        } else {
                            setError("Profile not found");
                        }
                    }
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
        return () => {
            unsubscribeBusStatus();
            unsubscribeSchoolStatus();
        };
    }, [deviceId]);

    const formatPhoneNumber = (phoneNumber) => {
        const cleanedNumber = phoneNumber.replace(/\D/g, '');
        return cleanedNumber.startsWith('65') ? cleanedNumber : `65${cleanedNumber}`;
    };

    const handleStatusChange = async (type, profile) => {
        const status = type === 'bus' ? busStatus : schoolStatus;
        if (!status) return;

        try {
            const statusRef = ref(database, `status/${deviceId}/${type}`);
            const timestamp = Date.now();
            
            // Calculate the times based on status
            let newInTime = inTime;
            let newOutTime = outTime;
            
            if (type === 'school') {
                if (status === 'in') {
                    setInTime(timestamp);
                    setOutTime(null);
                } else if (status === 'out') {
                    setOutTime(timestamp);
                     
                }
            }

            await set(statusRef, {
                status,
                timestamp,
                userType,
                inTime: inTime,
                outTime: outTime,
                profile
            });

            // Update local state
            // if (type === 'school') {
            //     if (status === 'in') {
            //         setInTime(timestamp);
            //         setOutTime(null);
            //     } else if (status === 'out') {
            //         setOutTime(timestamp);
            //     }
            // }
        } catch (err) {
            setError(err.message);
        }
    };

    const formatTime = (timestamp) => {
        if (!timestamp) return 'Not recorded';
        const date = new Date(timestamp);
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    if (loading) return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh"><CircularProgress /></Box>;
    if (!profile) return <Typography>Profile not found</Typography>;

    return (
        <div className="inner-div">
            <div className="front">
                <div className="front__bkg-photo"></div>
                <div
                    className="front__face-photo"
                    style={{ backgroundImage: `url(${profile.picture})` }}
                ></div>
                <div className="front__text_other">
                    <h3 className="front__text-header-other">{profile?.name}</h3>
                    <h3 className="front__text-para-other">Class: {profile?.childClass}</h3>
                    <p className="front__text-para-other">Address: {profile?.localAddress}</p>
                </div>

                <Box elevation={1} sx={{  px:2, mt: -13 }}>
                    <Typography variant="h6" gutterBottom align="left">Emergency Contacts</Typography>
                    {profile.emergencyContacts && profile.emergencyContacts.map((contact, index) => (
                        <Box key={index} sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography variant="body1" align="left">{contact.name}</Typography>
                                <Typography variant="body2" color="text.secondary" align="left">{contact.number}</Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    href={`tel:${contact.number}`}
                                    size="small"
                                    color="primary"
                                >
                                    <CallIcon />
                                </IconButton>
                                <IconButton
                                    href={`https://wa.me/${formatPhoneNumber(contact.number)}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    size="small"
                                    color="primary"
                                >
                                    <WhatsAppIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary" sx={{fontSize:"20px"}}>
                            School Status: {currentSchoolStatus || 'Not set'}
                        </Typography>
                       
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="textSecondary" sx={{fontSize:"20px"}}>
                            Bus Status: {currentBusStatus || 'Not set'}
                        </Typography>
                    </Grid>
                </Grid>

                {userType === 'security' && (
                    <Box sx={{ mt: 1, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>School Entry/Exit</Typography>
                        <RadioGroup
                            row
                            value={schoolStatus}
                            onChange={(e) => setSchoolStatus(e.target.value)}
                            sx={{
                                justifyContent: 'center',
                                mt: 1,
                                mb: 2
                            }}
                        >
                            <FormControlLabel value="in" control={<Radio />} label="In"  sx={{ '& .MuiFormControlLabel-label': { fontSize: '24px' } }}/>
                            <FormControlLabel value="out" control={<Radio />} label="Out"  sx={{ '& .MuiFormControlLabel-label': { fontSize: '24px' } }}/>
                        </RadioGroup>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleStatusChange('school', profile)}
                            disabled={!schoolStatus || schoolStatus === currentSchoolStatus}
                            sx={{ mt: 2, mb: 4, fontSize:"20px" }}
                        >
                            Confirm
                        </Button>
                    </Box>
                )}

                {userType === 'bus' && (
                    <Box sx={{ mt: 1, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>Bus Status</Typography>
                        <RadioGroup
                            row
                            value={busStatus}
                            onChange={(e) => setBusStatus(e.target.value)}
                            sx={{
                                justifyContent: 'center',
                                mt: 1,
                                mb: 2,
                            }}
                        >
                            <FormControlLabel value="pick" control={<Radio  />} label="Pick"  sx={{ '& .MuiFormControlLabel-label': { fontSize: '24px' } }} />
                            <FormControlLabel value="drop" control={<Radio />} label="Drop"  sx={{ '& .MuiFormControlLabel-label': { fontSize: '24px' } }} />
                        </RadioGroup>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleStatusChange('bus', profile)}
                            disabled={!busStatus || busStatus === currentBusStatus}
                            sx={{ mt: 2, mb: 4, fontSize:"20px" }}
                        >
                            Confirm
                        </Button>
                    </Box>
                )}

                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </div>
        </div>
    );
}

export default Profile;