import React from 'react';
import { 
  Container,
  Paper,
  Typography,
  Box,
  Divider,
  ThemeProvider,
  createTheme
} from '@mui/material';

const PrivacyPolicy = () => {
  const theme = createTheme({
    typography: {
      h1: {
        fontSize: '2.5rem',
        fontWeight: 500,
        marginBottom: '1rem',
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 500,
        marginBottom: '1rem',
        marginTop: '2rem',
      },
      body1: {
        marginBottom: '1.5rem',
        lineHeight: 1.7,
      },
    },
  });

  const sections = [
    {
      title: "Introduction and Overview",
      content: `This Privacy Policy outlines how "we," "our," or "us" manages, protects, and processes your personal information when you engage with our business through WhatsApp messaging and our online payment platform. We are committed to protecting your privacy and ensuring transparent information practices while providing our services. This policy applies to all interactions through our WhatsApp business account and associated payment transactions.`
    },
    {
      title: "Collection and Use of Information",
      content: `We collect various types of information to provide and improve our services. This includes personal information such as your name, contact details, and payment information, as well as technical data generated through your interaction with our services. When you communicate with us through WhatsApp, we maintain records of our conversations to provide better service and comply with legal requirements. Payment information is collected securely through our authorized payment processors, ensuring compliance with international security standards.`
    },
    {
      title: "WhatsApp Business Communications",
      content: `Our WhatsApp business communication follows strict protocols to protect your privacy and comply with messaging regulations. We only initiate conversations with users who have explicitly consented to receive communications from us. All business messages are clearly identified, and we maintain transparent communication practices. We respect messaging frequency limitations and promptly honor any requests to opt out of communications. Our WhatsApp usage adheres to Meta's platform terms and WhatsApp's business policies while ensuring compliance with local communication regulations.`
    },
    {
      title: "Payment Processing and Security",
      content: `We prioritize the security of your payment information through robust protection measures. All payment processing is conducted through certified payment processors who maintain PCI DSS compliance and implement industry-standard security protocols. We never store complete payment card information on our systems. Our payment processors employ encryption, fraud detection, and prevention mechanisms to protect your financial data. Regular security assessments and updates ensure that our payment processing remains secure and compliant with evolving standards.`
    },
    {
      title: "Data Protection and Storage",
      content: `Your information is protected through comprehensive security measures, including data encryption, access controls, and regular security audits. We implement industry-standard protocols to prevent unauthorized access, maintain data accuracy, and ensure appropriate use of information. Our employees receive regular training on data protection practices, and we maintain strict policies regarding access to customer information. Data backup systems ensure business continuity while protecting your information integrity.`
    },
    {
      title: "Sharing and Disclosure of Information",
      content: `We may share your information with specific third parties to facilitate our services, including payment processors and delivery partners where applicable. Any such sharing is governed by strict confidentiality agreements and data protection requirements. We may also disclose information when required by law, in response to legal processes, or to protect our rights and interests. We never sell your personal information to third parties for marketing purposes.`
    },
    {
      title: "Your Rights and Choices",
      content: `We respect and uphold your data privacy rights, including the right to access, correct, or delete your personal information. You can request details about the personal information we hold about you and how it is processed. If you wish to modify your communication preferences or withdraw consent for specific data processing activities, we provide clear mechanisms to do so. We respond to all privacy-related requests within the timeframes specified by applicable laws.`
    },
    {
      title: "Legal Compliance and International Standards",
      content: `Our privacy practices comply with relevant local and international data protection regulations, including GDPR where applicable. We maintain appropriate safeguards for international data transfers and regularly update our practices to align with evolving legal requirements. Our compliance program includes regular assessments, documentation of processing activities, and maintenance of required registrations with data protection authorities.`
    },
    {
      title: "Changes and Updates",
      content: `We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Significant changes will be communicated to you through WhatsApp or email, and continued use of our services after such notifications constitutes acceptance of the updated policy. We encourage you to review this policy regularly to stay informed about how we protect your information.`
    },
    {
      title: "Contact and Dispute Resolution",
      content: `For any privacy-related questions, concerns, or requests, please contact our dedicated privacy team at [contact email]. We are committed to resolving any privacy concerns promptly and fairly. If you believe your privacy rights have been violated, you may also file a complaint with your local data protection authority. We cooperate fully with regulatory investigations and provide necessary information to facilitate the resolution of privacy-related disputes.`
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h1">
              Privacy Policy and Terms of Use
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Last Updated: December 27, 2024
            </Typography>
          </Box>

          {sections.map((section, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              <Typography variant="h2">
                {section.title}
              </Typography>
              <Typography variant="body1">
                {section.content}
              </Typography>
              {index < sections.length - 1 && (
                <Divider sx={{ my: 4 }} />
              )}
            </Box>
          ))}

          <Box sx={{ mt: 4, pt: 2, borderTop: 1, borderColor: 'divider' }}>
            <Typography variant="body2" color="text.secondary">
              This Privacy Policy is governed by the local laws and shall be interpreted in accordance with these laws. 
              By using our services, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
            </Typography>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;