import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { BrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import InstaShareForm from "../pages/basicDetails/instashare-form-component";
import EditProfileForm  from "../pages/basicDetails/EditProfileForm";
import Profile from "../pages/profile/Profile";
import SProfile from "../pages/security/sProfile";
import Device from "../pages/device/Device";
import UserProfiles from '../pages/userProfiles/UserProfiles';

import Login from '../pages/login/Login';
import SchoolLogin from '../pages/login/SchoolLogin';
import ProtectedRoute from './ProtectedRoute';
import PrivacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import DataDeletion from "../pages/privacyPolicy/dataDelete";
import SchoolDashboard from "../pages/dashboard/schoolDashboard";
import Welcome from "../pages/dashboard/welcomeDashboard";


export default function BasicRoutes() {

  return (
    <Routes>
      <Route path="/home" element={<HomePage />} />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <UserProfiles />
        </ProtectedRoute>
      } />

      <Route path="/" element={
        <ProtectedRoute>
          <UserProfiles />
        </ProtectedRoute>
      } />
      <Route path="/register/:typeofuser/:deviceId" element={<InstaShareForm />} />
      <Route path="/register/" element={<InstaShareForm />} />
      <Route path="/register/:deviceId" element={<ProtectedRoute>
        <InstaShareForm />
      </ProtectedRoute>} />
      <Route path="/newregister" element={<ProtectedRoute>
        <InstaShareForm />
      </ProtectedRoute>} />
      <Route path="/edit/:deviceId/:profileId" element={<ProtectedRoute><EditProfileForm /></ProtectedRoute>} />
      <Route path="/em/:deviceId" element={<Profile />} />
      <Route path="/sem/:deviceId" element={<SProfile />} />
      <Route path="/device/:deviceId" element={<Device />} />
      <Route path="/login" element={<Login />} />
      <Route path="/school-login" element={<SchoolLogin />} />
      <Route path ="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path ="/data-deletion" element={<DataDeletion />} />
      <Route path="/class/:classId" element={<SchoolDashboard />} />
      <Route path="/security/dashboard" element={<Welcome />} />
    </Routes>
  )
}
