import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Alert,
  Divider,
  ThemeProvider,
  createTheme
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const DataDeletion = () => {
  const theme = createTheme({
    typography: {
      h1: {
        fontSize: '2.5rem',
        fontWeight: 500,
        marginBottom: '1rem',
      },
      body1: {
        marginBottom: '1.5rem',
        lineHeight: 1.7,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          {/* Header */}
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h1">
              Data Deletion Instructions
            </Typography>
          </Box>

          {/* Warning Alert */}
          <Alert 
            severity="warning" 
            icon={<WarningAmberIcon />}
            sx={{ mb: 4 }}
          >
            Please download your data before requesting deletion. Some information cannot be recovered after deletion.
          </Alert>

          {/* Main Content */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="body1">
              When you request account deletion, it's essential to safeguard your important information before proceeding. 
              We recommend downloading your complete data package, which includes transaction history, chat logs, payment records, 
              and delivery information. This ensures you retain necessary documentation for tax purposes and business records.
            </Typography>

            <Typography variant="body1">
              To initiate deletion, contact us at support@az-kraft.com. Upon verification, we'll begin the permanent deletion process, 
              which completed within 30 days. Your data will be removed from active systems, and backup data will be cleared within 90 days. 
              We'll notify our third-party processors to delete your information and send you a confirmation email once complete.
            </Typography>

            <Typography variant="body1">
              Please note that certain data must be retained to comply with legal requirements. This includes transaction records 
              (kept for 7 years), regulatory compliance documentation, and information pertinent to any ongoing legal matters. 
              Rest assured that this retained data remains protected under our privacy and security protocols.
            </Typography>

            <Typography variant="body1" sx={{ mb: 0 }}>
              The deletion process is irreversible. Once initiated, you cannot recover your account or associated data. 
              For any questions about data export or the deletion process, please email us at support@az-kraft.com.
            </Typography>
          </Box>

          <Divider sx={{ my: 4 }} />

    
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default DataDeletion;