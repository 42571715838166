import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button
} from '@mui/material';
import { database } from '../../firebase';
import { ref, onValue, set } from 'firebase/database';

import StudentStatusRow from './StudentStatusRow';

const initialSchoolData = {
    "play-group": {
        "name": "Play Group",
        "color": "#8bc34a",
        "totalStudents": 25,
        "totalTeachers": 2,
        "presentStudents": 23,
        "absentStudents": 2,
        "students": [
            {"id": 1, "name": "Student 1"},
            {"id": 5, "name": "Student 2"}
        ]
    },
    "nursery-1": {
        "name": "Nursery 1",
        "color": "#795548",
        "totalStudents": 30,
        "totalTeachers": 3,
        "presentStudents": 28,
        "absentStudents": 2,
        "students": [
            {"id": 5, "name": "Student 1"},
            {"id": 2, "name": "Student 2"}
        ]
    },
    "nursery-2": {
        "name": "Nursery 2",
        "color": "#ff9800",
        "totalStudents": 35,
        "totalTeachers": 3,
        "presentStudents": 33,
        "absentStudents": 2,
        "students": [
            {"id": 1, "name": "Student 1"},
            {"id": 2, "name": "Student 2"},
            {"id": 5, "name": "Student 1"}
        ]
    },
    "kindergarten-1": {
        "name": "Kindergarten 1",
        "color": "#2196f3",
        "totalStudents": 38,
        "totalTeachers": 4,
        "presentStudents": 36,
        "absentStudents": 2,
        "students": [
            {"id": 1, "name": "Student 1"},
            {"id": 2, "name": "Student 2"},
            {"id": 5, "name": "Student 1"}
        ]
    },
    "kindergarten-2": {
        "name": "Kindergarten 2",
        "color": "#9c27b0",
        "totalStudents": 40,
        "totalTeachers": 4,
        "presentStudents": 38,
        "absentStudents": 2,
        "students": [
            {"id": 1, "name": "Student 1"}
        ]
    }
};

async function uploadSchoolDataToFirebase(data) {
    try {
        const schoolDataRef = ref(database, 'classes');
        await set(schoolDataRef, {
            ...data,
            timestamp: new Date().toISOString(),
            lastUpdated: new Date().toISOString()
        });
        return {
            success: true,
            message: 'Data uploaded successfully to Firebase'
        };
    } catch (error) {
        console.error('Error uploading school data to Firebase:', error);
        return {
            success: false,
            message: error.message,
            error: error
        };
    }
}

const ClassView = () => {
    const { classId } = useParams();
    const [presentCount, setPresentCount] = useState(0);
    const [classData, setClassData] = useState({
        'kindergarten-1': {
          name: 'Kindergarten 2',
          totalStudents: 40,
          totalTeachers: 4,
          presentStudents: 38,
          absentStudents: 2,
          students: [
            { id: 1, name: 'Student 1',  },
            { id: 2, name: 'Student 2',   },
          ]
        }
    });
    const [currentTime, setCurrentTime] = useState('');
    const currentDate = new Date().toLocaleDateString();

    useEffect(() => {
        // Function to format time as HH:mm
        const formatTime = () => {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        // Set initial time
        setCurrentTime(formatTime());

        // Update time every minute
        const interval = setInterval(() => {
            setCurrentTime(formatTime());
        }, 60000); // Update every minute (60000 milliseconds)

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const classesRef = ref(database, 'classes');
        setPresentCount(0)
        try {
            const unsubscribe = onValue(classesRef, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setClassData(data);
                    console.log('Data fetched successfully:', data);
                } else {
                    console.log('No data available');
                    setClassData(initialSchoolData);
                }
            }, (error) => {
                console.error('Error fetching class data:', error);
                setClassData(null);
            });

            return () => {
                console.log('Cleaning up Firebase listener');
                unsubscribe();
            };
        } catch (error) {
            console.error('Error setting up Firebase listener:', error);
            return () => {};
        }
    }, []);

    const handleInitialUpload = async () => {
      
        const result = await uploadSchoolDataToFirebase(initialSchoolData);
  
    };

    useEffect(() => {
        // Firebase connection code (commented out)
        // const classRef = ref(database, `classes/${classId}`);
        // const unsubscribe = onValue(classRef, (snapshot) => {
        //     if (snapshot.exists()) {
        //         setClassData(snapshot.val());
        //     }
        // });
        // return () => unsubscribe();
    }, []);

    if (!classData) return null;

    const handleStudentStatusChange = (studentId, status) => {
        if(status === 'in'){
            setPresentCount(prev => prev + 1);
        }
    };

    const colorMap = {
        'play-group': '#8bc34a',
        'nursery-1': '#795548',
        'nursery-2': '#ff9800',
        'kindergarten-1': '#2196f3',
        'kindergarten-2': '#9c27b0'
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    
                {/* <Button 
                    variant="contained" 
                    onClick={handleInitialUpload}
                    sx={{ mb: 1 }}
                >
                    Upload Initial Data
                </Button> */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography
                    variant="h5"
                    sx={{
                        color: 'white',
                        backgroundColor: colorMap[classId] || 'purple',
                        p: 1,
                        borderRadius: 1
                    }}
                >
                    {classData.name}
                </Typography>
                <Box>
                    <Typography>Date: {currentDate}</Typography>
                    <Typography>Time: {currentTime}</Typography>
                </Box>
            </Box>

            <Box sx={{ mb: 3 }}>
                <Typography sx={{fontSize:"18px"}}>Total Students: {classData[classId]?.students?.length}</Typography>
                <Typography sx={{fontSize:"18px"}}>Total Teachers: {classData[classId]?.totalTeachers}</Typography>
                 {/* <Typography>Students Present: {presentCount}</Typography>
               <Typography>Students Absent: {classData[classId]?.absentStudents}</Typography> */}
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>NRIC/FIN</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Entry Time</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classData[classId]?.students?.map((student) => (
                            <StudentStatusRow key={student.id} student={student} onStatusChange={handleStudentStatusChange}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ClassView;