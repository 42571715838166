import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SchoolLogin = () => {
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState('teacher');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleGoogleSignIn = async () => {
    setLoading(true);
    setError('');
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      localStorage.setItem('userType', selectedRole);
      switch (selectedRole) {
        case 'teacher':
          navigate('/class/kindergarten-1');
          break;
        case 'bus':
          navigate('/security/dashboard');
          break;
        case 'security':
          navigate('/security/dashboard');
          break;
        default:
          navigate('/');
      }
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3
      }}>
        <Typography component="h1" variant="h5">
          School Login
        </Typography>

        <RadioGroup
          row
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          sx={{ 
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
          <FormControlLabel value="security" control={<Radio />} label="Security" />
          <FormControlLabel value="bus" control={<Radio />} label="Bus Driver" />
        </RadioGroup>

        <Button
          fullWidth
          variant="outlined"
          onClick={handleGoogleSignIn}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Sign in with Google'}
        </Button>

        {error && (
          <Typography color="error">
            {error}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default SchoolLogin;