import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Drawer,
  Typography,
  List,
  ListItemButton,
  ListItemText
} from '@mui/material';

const Navigation = () => {
  const navigate = useNavigate();
  const { classId } = useParams();

  const classList = [
    { id: 'play-group', name: 'Play Group', color: '#8bc34a' },
    { id: 'nursery-1', name: 'Nursery 1', color: '#795548' },
    { id: 'nursery-2', name: 'Nursery 2', color: '#ff9800' },
    { id: 'kindergarten-1', name: 'Kindergarten 1', color: '#2196f3' },
    { id: 'kindergarten-2', name: 'Kindergarten 2', color: '#9c27b0' }
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        paddingTop:"60px",
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: '#f5f5f5',
          paddingTop:"60px",
        },
      }}
    >
      <Box sx={{ overflow: 'auto', mt: 2 }}>
 
        <List>
          {classList.map((item) => (
            <ListItemButton
              key={item.id}
              selected={classId === item.id}
              onClick={() => navigate(`/class/${item.id}`)}
              sx={{
                // backgroundColor: classId === item.id ? item.color : 'inherit',
                color: classId === item.id ? 'grey' : 'inherit',
                my: 0.5,
                '&:hover': {
                  backgroundColor: item.color,
                  opacity: 0.9,
                  color: 'white'
                },
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}
          {/* <ListItemButton 
            onClick={() => navigate('/absent')}
            sx={{ backgroundColor: '#f44336', color: 'white', mt: 2 }}
          >
            <ListItemText primary="Absent" />
          </ListItemButton> */}
          {/* <ListItemButton 
            onClick={() => navigate('/past-records')}
            sx={{ backgroundColor: '#e57373', color: 'white', mt: 1 }}
          >
            <ListItemText primary="Past Record" />
          </ListItemButton> */}
        </List>
      </Box>
    </Drawer>
  );
};

export default Navigation;